import Checkbox from "./Checkbox";

const ServiceAgreement = ({
  loggedIn,
  ids,
  requiresMarketingOptIn,
}) => {
  let consentItems = [];
  if (requiresMarketingOptIn) {
    const userStatus = loggedIn ? "in" : "out";
    consentItems.push(
      <Checkbox
        key={ids.marketingConsent}
        id={ids.marketingConsent}
        label={i18next.t("auth:form.campaign_opt_in", {
          email_pref_link: i18next.t(
            `auth:form.email_pref_link_logged_${userStatus}`,
            { host: MARKETO_URI }
          ),
        })}
      />
    );
  }
  consentItems.push(
    <Checkbox
      key={ids.termsConsent}
      id={ids.termsConsent}
      label={i18next.t("auth:form.consent_to_terms", {
        product: i18next.t("auth:form.consent.360_html"),
        terms_link: "https://www.articulate.com/360/terms",
        privacy_link: "https://www.articulate.com/trust/privacy",
        cookie_link: "https://www.articulate.com/trust/privacy#cookies",
      })}
    />
  );
  return (
    <div className="grid__row grid__row--centered margin--topXSmall form__group">
      <div className="grid__col--9 grid__col--portrait--10 grid__col--small--11 grid__col--bleed align--left">
        {consentItems}
      </div>
    </div>
  );
};

export default ServiceAgreement;
